import React, { useEffect } from 'react'
import { Card, Grid,  Typography, Table, Box,  Switch } from '@mui/material'
import MDBox from 'components/MDBox'
import { useDispatch } from 'react-redux'
import { getChecklist, updateCheckListStatus } from '../../../../redux/slices/hyperdrive/setting'
import { useSelector } from 'react-redux'
import { openSnackbar } from '../../../../redux/slices/common'

export default function Checklist() {
    const dispatch = useDispatch()
    const { mainChecklistData } = useSelector(state => state?.commonHyperdrive)
  
    useEffect(() => {
        dispatch(getChecklist())
    }, [])

    const statusChangeCheckList = (e, value, checklistId) => {
        const allTransportations = mainChecklistData?.data || [];
        const activeCount = allTransportations.filter(t => t.isActive).length;
    
        if (!value && activeCount === 1) {
            showResponseMessage("At least one transportation must be active.", 'error');
            return;
        }
        const payload = { isActive: value }
        dispatch(updateCheckListStatus({ data: payload, checklistId }))
            .unwrap()
            .then(res => {
                dispatch(getChecklist())
                showResponseMessage(res?.message, 'success');
            })
            .catch(err => {
                showResponseMessage(err?.message, 'error');
            });
    };

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }

    return (
        <Card sx={{ pt: 4, pb: 3, px: 4, mb: 4, borderRadius: 0 }}>
            <MDBox display="flex" alignItems="center" mb={2} mt={1}>
                <MDBox>
                    <Typography variant='h5' fontWeight="semibold" color="#F5F5FF">Checklist</Typography>
                </MDBox>
            </MDBox>
            <Grid container alignItems="center" spacing={{ xs: 3, md: 3 }} mb={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Table className='hw-table'>
                        <thead>
                            <tr>
                                <th>
                                    <Typography className='text-black-400 text-12 text-left'>Checklist</Typography>
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                mainChecklistData?.data?.map((checklistData) => {
                                    return (
                                        <tr key={checklistData?._id}>
                                            <td>
                                                <Typography className='text-white-700 text-14'>
                                                    {checklistData?.title}
                                                </Typography>
                                            </td>
                                            <td>
                                                <Box display="flex" alignItems="center" justifyContent="end">
                                                    <Switch checked={checklistData?.isActive} onChange={(e, value) => statusChangeCheckList(e, value, checklistData?._id)} />
                                                </Box>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </Grid>
            </Grid>
        </Card>
    )
}