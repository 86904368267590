import { Close } from '@mui/icons-material'
import { Box, Button, FormHelperText,  Stack, Switch, TextField, Typography } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import GoogleSuggestion from 'components/googleMaps/GoogleSuggestion'
import { addStartTask } from '../../../redux/slices/hyperdrive/createTask';
import { openSnackbar } from '../../../redux/slices/common';
import MDButton from 'components/MDButton';
import { useSelector } from 'react-redux';
import { customApiSwitch } from '../../../redux/slices/modalSlice';
import { useSearchParams } from 'react-router-dom'

export default function StartTask({ closeModal }) {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({})
    const { loading: loading = false } = useSelector(state => state?.taskHyperDrive)
    const [searchParams, setSearchParams] = useSearchParams();
    let roleTab = searchParams.get('roleTab')
    const [formData, setFormData] = useState({
        taskDescription: "",
        taskType: "startTask",
    })
    const addressError = useRef(false)
    const [dataSource, setDataSource] = useState([{}, {}])
    const [regionData, setRegionData] = useState({})
    console.log("regionData", regionData);

    const startTaskHandler = (event) => {
        // Destructure name and value from the event target
        const { name, value } = event.target;

        // Update the form data state based on the input change
        setFormData(prevData => {
            // Create a new data object by merging previous data with the new value
            const newData = {
                ...prevData,
                [name]: value
            };

            // Return the updated data object
            return newData;
        });
    };

    const validateTask = () => {
        let isError = false
        let errors = {}
        if (!formData?.taskDescription?.trim()) {
            isError = true
            errors = { ...errors, taskDescription: 'Task Description is required' }
        }

        return { errors, isError }
    }

    const submitHandler = () => {
        // Validate the task and obtain validation results
        const validationResult = validateTask();
        let isError = validationResult.isError; // Flag indicating if there's an error
        let errors = { ...validationResult.errors }; // Copy existing errors
        setErrors(errors); // Update the state with validation errors

        // If validation has errors, exit the handler
        if (isError) return;


        // Prepare the payload for the API request
        const payload = { ...formData };

        // Dispatch the action to start the task
        dispatch(addStartTask({ data: payload }))
            .unwrap() // Unwrap the promise to handle the response
            .then(res => {
                // Show a success message on successful submission
                showResponseMessage(res?.message, 'success');
                closeModal(); // Close the modal after submission
                // Dispatch actions to reset the API data and update state
                dispatch(customApiSwitch({
                    dataSwitch: 'API_DATA_ROUTE',
                    routeData: roleTab
                }));
            })
            .catch(err => {
                // Show an error message if the submission fails
                showResponseMessage(err?.message, 'error');
            });
    };

    const showResponseMessage = (message, errortype) => {
        if (errortype === 'success') {
            dispatch(openSnackbar({ content: message, color: 'success', icon: 'check' }))
        }
        if (errortype === 'error') {
            dispatch(openSnackbar({ content: message, color: 'error', icon: 'warning' }))
        }
    }
    const handleSwitcher = (e, value, id) => { }
    const handleAddressChange = (addressed) => {
        const address = {
            address1: addressed?.address || "",
            city: addressed?.city || "",
            state: addressed?.state || "",
            zipcode: addressed?.zipcode || "",
            country: addressed?.country || "USA",
            latitude: addressed?.lat ? addressed?.lat.toFixed(8) : "",
            longitude: addressed?.long ? addressed?.long.toFixed(8) : "",
        };
        setRegionData({ ...regionData, address });
    }

    const googleAddressErrorHandler = (status = false) => {
        addressError.current = status
        if (status) {
            setErrors({ ...errors, address: 'Address is required' })
        }
        else {
            setErrors(prevErrors => ({ ...prevErrors, address: '' }));
        }
    }

    return (
        <Box className="cus-wrapper-drawer">
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} style={{ borderBottom: "1px solid  #1E1E24" }} className="drawer-head-sec">
                <Typography className="text-14 text-white-700">Create Start Task</Typography>
                <Close onClick={closeModal} style={{ cursor: "pointer", color: "#ffffff" }} />
            </Box>
            <Box className="drawer-scroll-sec">
                <Box px={2} py={4}>
                    <Stack direction='column' mb={4}>
                        <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Task Info</Typography>
                        <Stack direction='column' gap={2} mb={1} className='dark-field'>
                            <TextField
                                autoComplete="off"
                                fullWidth
                                helperText={errors.taskDescription}
                                error={errors?.taskDescription}
                                label={'Task Description'}
                                name="taskDescription"
                                onChange={(e) => startTaskHandler(e)}
                                className="form-input"
                                value={formData?.taskDescription || ''}
                            />
                        </Stack>
                    </Stack>
                    <Typography mb={1} fontWeight={600} className='text-20 text-white-700'>Region List</Typography>
                    {
                        dataSource?.map((data) => {
                            return (
                                <div key={data?._id}>
                                    <Box display="flex" alignItems="center" justifyContent="">
                                        <Switch checked={data?.isActive} onChange={(e, value) => handleSwitcher(e, value, data?._id)} />
                                        <Typography className="text-14 text-white-700">Region 1</Typography>
                                        <Stack direction='column' mb={4}>
                                            <Stack direction='column' gap={2} mb={1} className='dark-field hd-google-suggestions'>
                                                <GoogleSuggestion addressValue={regionData?.address?.address}
                                                    googleAddressErrorHandler={googleAddressErrorHandler} onClearAddress={(address) => setRegionData({ ...regionData, address })}
                                                    addressChangeHandler={(address) => handleAddressChange(address)} />
                                                {errors?.address && <FormHelperText error>{errors?.address}</FormHelperText>}
                                            </Stack>
                                        </Stack>
                                    </Box>
                                </div>
                            )
                        })
                    }
                </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" p={2} gap={2} style={{ borderTop: "1px solid  #1E1E24" }} className="drawer-bottom-btns">
                <Button variant="outlined" color="secondary" className='d-flex align-item-center gap-2' onClick={closeModal}>
                    Cancel
                </Button>
                <MDButton variant="contained" color="primary" className='d-flex align-item-center gap-2 green-btn' onClick={() => submitHandler()} disabled={loading} loading={loading}>
                    Create Task
                </MDButton>
            </Box>

        </Box>
    )
}