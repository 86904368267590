import { CloseRounded } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import SearchIcon from 'common/customIcons/search';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDebounce } from 'hooks/useDebounce';
import { AgGridTable } from 'components/tables/AgGridTable';
import { useDispatch } from 'react-redux';
import { getDriverAnalyticsInfo } from '../../../../redux/slices/hyperdrive/driverAnalytics';
import { useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { formatTimestampToTimeTimezone } from 'utilities/common';
import HyperDriveMapDate from 'components/dateRangePicker/hyperDriveMapDate';
import { convertMinutesToHoursAndMinutes } from 'utilities/common';
import { formatTimestampTo12HourTime } from 'utilities/common';

export default function Breaks({ driverId }) {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch()
  const pageRef = useRef(0)
  const searchTermRef = useRef("")
  const mainGridApiRef = useRef('')
  const gridRefApi = useRef(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [filterShow, setFilterShow] = useState(false)
  const { driverDetails, loading: loading = false } = useSelector(state => state?.driverAnalyticsHyperDrive)
  const driverList = driverDetails?.data?.breaks
  const fromDateRef = useRef('');
  const toDateRef = useRef('');
  fromDateRef.current = startDate ? formatTimestampToTimeTimezone(startDate) : "";
  toDateRef.current = endDate ? formatTimestampToTimeTimezone(endDate) : "";

  const columnDefs = useMemo(() => [
    {
      headerName: 'Break Name',
      field: 'breakName',
      suppressMenu: false,
      minWidth: 180,
      flex: 0.5,
      cellStyle: { whiteSpace: 'normal', lineHeight: '1.5' },
      sortable: false
    },
    {
      headerName: 'Date',
      field: 'date',
      suppressMenu: false,
      minWidth: 180,
      flex: 0.5,
      cellRenderer: (props) => {
        return props?.value ? props?.value : "-";
      },
      sortable: false
    },
    {
      headerName: 'Start Break',
      field: 'startBreak',
      suppressMenu: false,
      minWidth: 180,
      flex: 0.5,
      cellRenderer: (props) => {
        return props?.value ? formatTimestampTo12HourTime(props?.value) : "-";
      },
      sortable: false
    },
    {
      headerName: 'End Break',
      field: 'endBreak',
      suppressMenu: false,
      minWidth: 180,
      cellRenderer: (props) => {
        return props?.value ? formatTimestampTo12HourTime(props?.value) : "-";
      },
      flex: 0.5,
      sortable: false
    },
    {
      headerName: 'Planned Duration',
      field: 'plannedDuration',
      minWidth: 180,
      flex: 0.5,
      suppressMenu: false,
      cellRenderer: (props) => (
        <span className={props?.value < 0 ? "relativeDifferenceDark" : ""}>{`${props?.value < 0 ? '-' : ''} ${convertMinutesToHoursAndMinutes(props?.value)}`}</span>
      ),
      sortable: false
    },
    {
      headerName: 'Actual Duration',
      field: 'actualDuration',
      minWidth: 180,
      flex: 0.5,
      suppressMenu: false,
      cellRenderer: (props) => (
        <>  {props?.value ? <span className={props?.value < 0 ? "relativeDifferenceDark" : ""} > {`${props?.value < 0 ? '-' : ''} ${convertMinutesToHoursAndMinutes(props?.value)}`}</span > : "-"}   </>
      ),
      sortable: false
    },
    {
      headerName: 'Difference Duration',
      field: 'differenceDuration',
      suppressMenu: false,
      minWidth: 180,
      flex: 0.5,
      cellRenderer: (props) => {
        return <>  {props?.value ? <span className={props?.value < 0 ? "relativeDifferenceDark" : ""}>{`${props?.value < 0 ? '-' : ''} ${convertMinutesToHoursAndMinutes(props?.value)}`}</span> : "-"}   </>
      },
      cellStyle: { textAlign: "right", justifyContent: "end" },
      headerClass: "right-aligned-cell-header",
      sortable: false
    },
  ], []);

  const getSortInfo = useCallback((data) => {
    const result = {};
    data.forEach((item) => {
      if (item?.colId === 'memberType') {
        result[item.colId] = item.sort === 'asc' ? 'AdultUse' : 'MedicinalUser';
      } else {
        result[item.colId] = item.sort === 'asc';
      }
    });
    return result;
  }, []);

  const getRows = useCallback(async (params, apply) => {
    gridRefApi.current = params;
    let filter = {};
    if (params?.sortModel.length) {
      filter = { ...getSortInfo(params?.sortModel) };
    }
    try {
      const res = await dispatch(getDriverAnalyticsInfo({
        driverId,
        data: {
          analyticType: 'breaks',
          skip: pageRef.current,
          limit: 20,
          search: searchTermRef.current,
          from: (fromDateRef.current && toDateRef.current) && fromDateRef.current ? fromDateRef.current : "",
          to: (fromDateRef.current && toDateRef.current) && toDateRef.current ? toDateRef.current : "",
        },
      })).unwrap();

      const { breaks, totalBreaks } = res?.data;
      const driverListArray = breaks?.map((item) => ({
        ...item,
        status: item?.status ? 'On Duty' : 'Off Duty',
      }));
      params.successCallback([...driverListArray], totalBreaks);
      pageRef.current += 20;
    } catch {
      params.successCallback([], 0);
    }
  }, [dispatch, getSortInfo, driverId]);

  const searchFn = useCallback((apply) => {
    const dataSource = {
      getRows: (params) => getRows(params, apply),
    };
    mainGridApiRef.current.ensureIndexVisible(0, null);
    mainGridApiRef.current.setGridOption('datasource', dataSource);
  }, [getRows]);

  const handleSearch = useDebounce((apply) => {
    pageRef.current = 0;
    searchFn(apply);
  }, 500);

  const searchHandler = useCallback((searchValue) => {
    setSearchTerm(searchValue);
    searchTermRef.current = searchValue;
    handleSearch("apply");
  }, [handleSearch]);

  // Clear all handler
  const clearAllHandler = useCallback(() => {
    searchFn();
    pageRef.current = 0;
    toDateRef.current = '';
    fromDateRef.current = '';
    setDateRange([null, null])
  }, [searchFn]);

  useEffect(() => {
    if (mainGridApiRef.current) {
      if (loading) {
        mainGridApiRef.current.showLoadingOverlay();
      } else if (driverDetails?.data?.totalBreaks === 0) {
        mainGridApiRef.current.showNoRowsOverlay();
      } else {
        mainGridApiRef.current.hideOverlay();
      }
    }
  }, [driverList, loading]);
  console.log("loading ana", loading,driverDetails?.data?.totalBreaks);
  const applyHandler = () => {
    pageRef.current = 0;
    if (toDateRef.current && fromDateRef.current) {
      searchFn("apply");
    }
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
        <Typography variant='h4' className='text-20 text-white-700'>Breaks</Typography>
        <Stack direction="row" justifyContent={{ xs: "start", sm: "end" }} flexWrap="wrap" alignItems="center" gap={1.5} className="hd-search-filter">
          <TextField autoComplete="off" className="custom-search-field dark-field" placeholder="Search" label=""
            value={searchTerm} onChange={(e) => searchHandler(e.target.value)}
            InputProps={{
              startAdornment: <InputAdornment position="start">
                <IconButton size='small'>
                  <SearchIcon width='18px' height='18px' />
                </IconButton>
              </InputAdornment>,
              endAdornment: searchTerm?.length > 0 ? <InputAdornment>
                <IconButton onClick={() => searchHandler('')} size='small'>
                  <CloseRounded />
                </IconButton>
              </InputAdornment> : null
            }}
          />
          <Button variant="outlined" color="primary"
            className={filterShow ? "hd-filter-dropdown d-flex align-item-center gap-2 active-filter" : "hd-filter-dropdown d-flex align-item-center gap-2"}
            onClick={() => setFilterShow(!filterShow)} >
            <span>Filter</span>
            <ArrowDropDownIcon />
          </Button>
        </Stack>
      </Box>
      {
        filterShow &&
        <Box className="cus-filter-sec" mb={1}>
          <Grid className='filter-box' container alignItems="center" spacing={1.5}>
            <Grid item xs={12} sm={12} md={12} xxl={12}>
              <Box className="filter-price-unit">
                <Stack direction='row' justifyContent='flex-end' width='100%' gap={2} >
                  <Grid item xs={12} sm={12} md={12} xxl={12}>
                    <Box display="flex" alignItems="center" justifyContent='flex-end' gap={2} className="w-100">
                      <Box className="filter-pro-status hd-date-field dark-field dark-calendar">
                        <Stack direction='row' alignItems="center">
                          <HyperDriveMapDate setDateRange={setDateRange} dateRange={dateRange} showIcon={true} />
                        </Stack>
                      </Box>
                      <Box className="filter-btns">
                        <Stack direction='row' alignItems="center" gap={1} className='w-100'>
                          <Button className="filter-clear-all" onClick={clearAllHandler} size="small" variant="text" color="secondary" style={{ width: "100px" }}>
                            Clear All
                          </Button>
                          <Button className="filter-apply" onClick={() => applyHandler()} size="small" variant="contained" color="primary" style={{ width: "120px" }}>
                            Apply
                          </Button>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      }
      <div className="ag-theme-quartz-dark">
        <AgGridTable
          col={columnDefs}
          mainGridApiRef={mainGridApiRef}
          getRows={getRows}
          pageRef={pageRef}
          isLoading={loading}
          style={{ height: "70vh" }}
          gridOptions={{ rowHeight: 64, }}
          maxLimit={20}
        />
      </div>
    </Box>
  )
}