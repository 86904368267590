import {  Box, List, ListItem, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { getDriverAnalyticsInfo } from '../../../redux/slices/hyperdrive/driverAnalytics';
import { useDispatch } from 'react-redux';
import noDataFound from 'assets/images/nothing-found.svg';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { formatTimestampToWithoutTimeToday } from 'utilities/common';
import { addCommas } from 'utilities/common';
import Loader from 'common/Loader';
import { formatTimestampTo12HourTime } from 'utilities/common';

const DriverCheckInOut = ({ driverId }) => {
    const dispatch = useDispatch()
    const [posts, setPosts] = useState([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const observer = useRef();

    const loadMorePosts = useCallback(async (driverId) => {
        setLoading(true);
        try {
            const limit = 20;
            const skip = page * limit;
            const res = await dispatch(getDriverAnalyticsInfo({
                driverId,
                data: {
                    analyticType: "checklist",
                    skip,
                    limit: 20
                }
            })).unwrap();
            let newPosts = res?.data?.checkList

            if (newPosts?.length === 0) {
                setHasMore(false);
            } else {
                setHasMore(false);
                setPosts((prevPosts) => [...prevPosts, ...newPosts]);
            }
        } catch (err) {
            setHasMore(false);
            setLoading(false);
        } finally {
            setHasMore(false);
            setLoading(false);
        }
    }, [dispatch, page]);

    useEffect(() => {
        if (hasMore) {
            loadMorePosts(driverId);
        }
    }, [loadMorePosts, hasMore]);

    useEffect(()=>{
        setPage(0)
        setPosts([])
        setHasMore(true);
    },[driverId])    

    const lastPostElementRef = useCallback(
        (node) => {
            if (loading || !hasMore) return;
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    return (
        <List className="d-flex flex-column gap-3">
            {
                loading ? <Box className='loader-multi-drawer'>
                    <div className='d-flex align-item-center jc-center h-100'><Loader /></div></Box> :
                    <>
                        {posts?.length === 0 ?
                            <Box textAlign="center" className="w-100 d-flex align-item-center jc-center flex-column gap-2 no-data-found loader-multi-drawer">
                                <img style={{ maxWidth: "120px" }} src={noDataFound} alt="No Results Found" />
                                <p className='text-12'><strong>No Results Found</strong></p>
                            </Box>
                            :
                            <>   {
                                posts?.map((data, idx) => {
                                    return (
                                        <ListItem ref={posts.length === idx + 1 ? lastPostElementRef : null}
                                            key={data?._id} className="d-flex flex-column p-3 bg-grey-900 border-radius-8 border-none gap-3 cur-pointer">
                                            <div className="w-100 d-flex flex-column ">
                                                <Typography variant='h4' className='text-white-700 text-16 f-wrap line-height-normal'>
                                                    {formatTimestampToWithoutTimeToday(data?.checkIn)}
                                                </Typography>
                                                <div className="w-100 d-flex align-item-star my-2 gap-3">
                                                    <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                        <AccessTimeIcon className="text-purple-100 text-24" />
                                                        <div className="w-100 d-flex flex-column align-item-center">
                                                            <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                                <Typography className="text-black-200 text-12">Check In</Typography>
                                                                <Typography className="text-black-500 text-12">{data?.checkIn ? `${formatTimestampTo12HourTime(data?.checkIn)}` : "-"}</Typography>
                                                            </div>
                                                            <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                                <Typography className="text-black-200 text-12">Check Out</Typography>
                                                                <Typography className="text-black-500 text-12">{data?.checkOut ? `${formatTimestampTo12HourTime(data?.checkOut)}` : "-"}</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="w-100 d-flex align-item-star gap-2 border-radius-10 bg-black-800 pl-2 pr-3 py-2">
                                                        <PaidOutlinedIcon className="text-yellow-100 text-24" />
                                                        <div className="w-100 d-flex flex-column align-item-center">
                                                            <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                                <Typography className="text-black-200 text-12">Starting Cash</Typography>
                                                                <Typography className="text-black-500 text-12">${data?.drawerStartingCash ? addCommas(data?.drawerStartingCash) : 0}</Typography>
                                                            </div>
                                                            <div className="w-100 d-flex align-item-center jc-s-btwn">
                                                                <Typography className="text-black-200 text-12">Total Cash</Typography>
                                                                <Typography className="text-black-500 text-12">${data?.drawerTotalCash ? addCommas(data?.drawerTotalCash) : 0}</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Typography className="text-black-400 font-w-600 text-14">Total Mileage</Typography>
                                                <div className="w-100 d-flex gap-3">
                                                    <Typography className="text-black-400 text-14">
                                                        Miles Travel
                                                        <span className="text-white-900 font-w-600 text-16 ml-2">{data?.totalMiles ? data?.totalMiles : "-"}</span>
                                                    </Typography>
                                                    <Typography className="text-black-400 text-14">
                                                        Time Spent
                                                        <span className="text-white-900 font-w-600 text-16 ml-2">{data?.totalHours ? data?.totalHours : "-"}</span>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </ListItem>
                                    )
                                })
                            }
                            </>
                        }
                    </>
            }
        </List>
    )
}

export default DriverCheckInOut